<template>
  <div class="">
    <b-card>
      <b-row>
        <b-col lg="3">
          <b-img :src="billingDetail.order_items.image_url ? apiFile + billingDetail.order_items.image_url : require('@/assets/images/elp/empty.png')" class="img-fluid"></b-img>
        </b-col>
        <b-col lg="9">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <h2>{{ billingDetail.order_items.name }}</h2>
            <b-badge pill variant="success" v-if="billingDetail.status == 'PAID'"> Sudah Bayar </b-badge>
            <b-badge pill variant="info" v-if="billingDetail.status == 'UNPAID'"> Menunggu </b-badge>
            <b-badge pill variant="danger" v-if="billingDetail.status == 'FAILED' || billingDetail.status == 'EXPIRED' || billingDetail.status == 'REFUND'"> Dibatalkan </b-badge>
          </div>
          <b-row class="mt-4">
            <b-col md="3" class="mt-1">
              <span> No. Invoice</span> <br />
              <b>{{ billingDetail.merchant_ref }}</b>
            </b-col>
            <b-col md="3" class="mt-1">
              <span>Tanggal Transaksi</span> <br />
              <b>{{ humanDate(billingDetail.created_at) }}</b>
            </b-col>
            <b-col md="6" class="mt-1">
              <b-button variant="outline-primary " :to="{ name: 'billing-detail-invoice' }"> <feather-icon icon="DownloadIcon" /> Lihat Invoice</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BImg, BBadge, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
  },
  computed: {
    billingDetail() {
      return this.$store.state.transaksi.detailBilling;
    },
    apiFile() {
      return "https:/file.elp-dev.my.id/";
    },
  },
};
</script>

<style></style>
